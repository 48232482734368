import {mapGetters, mapMutations} from "vuex";

export default {
  name: "code",
  components:{
  },
  data(){
    return{

    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
    })
  },
  created() {
    let code = this.$route.params.code;

    if (code) {
      localStorage.setItem('hero_code', code);
    }

    // detect if user authentificated
    if (this.isAuthenticated) {
      console.log('user auth ok - go to list')
      this.$router.push({name: 'list'}).catch(() => {
        console.log()
      })
    } else {
      console.log('user NOT auth - go to main')
      this.$router.push({name: 'main'}).catch(() => {
        console.log()
      })
    }


    console.log('test', this.$route.params)
  },

  methods:{
    ...mapMutations({
    })
  }
}
